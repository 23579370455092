/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../Ihouse/helpers'
import DashBookingModal from '../Modals/DashBookingModal'
import axios from '../../Helpers/Api_instance'
import {useNavigate} from 'react-router-dom'

type Props = {
  className: string
}

const DashBoardTableOne: React.FC<Props> = ({className}) => {
  const [todaysBookings, setTodayBookings] = useState([])
  const [previousBookings, setPreviousBookings] = useState([])
  const [upcomingBookings, setUpComingBookings] = useState([])
  const [id, setId] = useState('')
  const navigate = useNavigate()

  const images = [
    toAbsoluteUrl('/media/svg/avatars/018-girl-9.svg'),
    toAbsoluteUrl('/media/svg/avatars/014-girl-7.svg'),
    toAbsoluteUrl('/media/svg/avatars/043-boy-18.svg'),
    toAbsoluteUrl('/media/svg/avatars/018-girl-9.svg'),
  ]

  const getTodays = async () => {
    try {
      const res = await axios.get('/bookings/today/bookings')
      if (res && res.data) {
        setTodayBookings(res.data.todayBookings.sort((a: any, b: any) => b.id - a.id))
      }
    } catch (error: any) {
      console.log(error.message)
    }
  }

  const getPreviouos = async () => {
    try {
      const res = await axios.get('/bookings/previous/bookings')
      if (res && res.data) {
        setPreviousBookings(res.data.previousBookings.sort((a: any, b: any) => b.id - a.id))
      }
    } catch (error: any) {
      console.log(error.message)
    }
  }

  const getUpComing = async () => {
    try {
      const res = await axios.get('/bookings/upcoming/bookings')
      if (res && res.data) {
        setUpComingBookings(res.data.upcomingBookings.sort((a: any, b: any) => b.id - a.id))
      }
    } catch (error: any) {
      console.log(error.message)
    }
  }

  const getAllBookings = async () => {
    await getTodays()
    await getPreviouos()
    await getUpComing()
  }

  useEffect(() => {
    getAllBookings()
  }, [])
  return (
    <>
      <div className={`card ${className}`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Bookings</span>
          </h3>
          <div className='card-toolbar'>
            <ul className='nav'>
              <li className='nav-item'>
                <a
                  className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bold px-4 me-1'
                  data-bs-toggle='tab'
                  href='#kt_table_widget_6_tab_1'
                >
                  Today
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-warning fw-bold px-4 me-1'
                  data-bs-toggle='tab'
                  href='#kt_table_widget_6_tab_2'
                >
                  Upcoming
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-info fw-bold px-4'
                  data-bs-toggle='tab'
                  href='#kt_table_widget_6_tab_3'
                >
                  Previous
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='tab-content'>
            <div className='tab-pane fade show active' id='kt_table_widget_6_tab_1'>
              <div className='table-responsive'>
                <table className='table align-middle gs-0 gy-3'>
                  <thead>
                    <tr>
                      <th className='p-0 w-50px'></th>
                      <th className='p-0 min-w-150px'></th>
                      <th className='p-0 min-w-140px'></th>
                      <th className='p-0 min-w-120px'></th>
                    </tr>
                  </thead>
                  <tbody>
                    {todaysBookings.slice(0, 10).map((e: any, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            <div className='symbol symbol-50px me-2'>
                              <span className='symbol-label'>
                                <img
                                  src={images[Math.floor(Math.random() * 3)]}
                                  className='h-75 align-self-end'
                                  alt=''
                                />
                              </span>
                            </div>
                          </td>
                          <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                              {e.user.first_name}
                            </a>
                            <span className='text-muted fw-semibold d-block'>{e.user.phone}</span>
                          </td>
                          <td>
                            <span className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                              {e.date}
                            </span>
                            <span className='text-muted fw-semibold d-block fs-7'>{e.time}</span>
                          </td>
                          <td className='text-center'>
                            <span
                              className={
                                e.status == 1
                                  ? 'badge badge-light-success fs-7 fw-bold'
                                  : e.status == 0
                                  ? 'badge badge-light-warning fs-7 fw-bold'
                                  : 'badge badge-light-danger fs-7 fw-bold'
                              }
                            >
                              {(e.status == 0 && 'Pending') ||
                                (e.status == 1 && 'Accepted') ||
                                (e.status == 2 && 'Rejected')}
                            </span>
                          </td>
                          <td className='text-end'>
                            <a
                              href='#'
                              className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_10'
                              onClick={() => {
                                setId(e.id)
                              }}
                            >
                              <KTIcon iconName='arrow-right' className='fs-2' />
                            </a>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            <div className='tab-pane fade' id='kt_table_widget_6_tab_2'>
              <div className='table-responsive'>
                <table className='table align-middle gs-0 gy-3'>
                  <thead>
                    <tr>
                      <th className='p-0 w-50px'></th>
                      <th className='p-0 min-w-150px'></th>
                      <th className='p-0 min-w-140px'></th>
                      <th className='p-0 min-w-120px'></th>
                    </tr>
                  </thead>
                  <tbody>
                    {upcomingBookings.slice(0, 10).map((e: any, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            <div className='symbol symbol-50px me-2'>
                              <span className='symbol-label'>
                                <img
                                  src={images[Math.floor(Math.random() * 3)]}
                                  className='h-75 align-self-end'
                                  alt=''
                                />
                              </span>
                            </div>
                          </td>
                          <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                              {e.user.first_name}
                            </a>
                            <span className='text-muted fw-semibold d-block'>{e.user.phone}</span>
                          </td>
                          <td>
                            <span className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                              {e.date}
                            </span>
                            <span className='text-muted fw-semibold d-block fs-7'>{e.time}</span>
                          </td>
                          <td className='text-center'>
                            <span
                              className={
                                e.status == 1
                                  ? 'badge badge-light-success fs-7 fw-bold'
                                  : e.status == 0
                                  ? 'badge badge-light-warning fs-7 fw-bold'
                                  : 'badge badge-light-danger fs-7 fw-bold'
                              }
                            >
                              {(e.status == 0 && 'Pending') ||
                                (e.status == 1 && 'Accepted') ||
                                (e.status == 2 && 'Rejected')}
                            </span>
                          </td>
                          <td className='text-end'>
                            <a
                              href='#'
                              className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_10'
                              onClick={() => {
                                setId(e.id)
                              }}
                            >
                              <KTIcon iconName='arrow-right' className='fs-2' />
                            </a>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='tab-pane fade' id='kt_table_widget_6_tab_3'>
              <div className='table-responsive'>
                <table className='table align-middle gs-0 gy-3'>
                  <thead>
                    <tr>
                      <th className='p-0 w-50px'></th>
                      <th className='p-0 min-w-150px'></th>
                      <th className='p-0 min-w-140px'></th>
                      <th className='p-0 min-w-120px'></th>
                    </tr>
                  </thead>
                  <tbody>
                    {previousBookings.slice(0, 10).map((e: any, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            <div className='symbol symbol-50px me-2'>
                              <span className='symbol-label'>
                                <img
                                  src={images[Math.floor(Math.random() * 3)]}
                                  className='h-75 align-self-end'
                                  alt=''
                                />
                              </span>
                            </div>
                          </td>
                          <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                              {e.user.first_name}
                            </a>
                            <span className='text-muted fw-semibold d-block'>{e.user.phone}</span>
                          </td>
                          <td>
                            <span className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                              {e.date}
                            </span>
                            <span className='text-muted fw-semibold d-block fs-7'>{e.time}</span>
                          </td>
                          <td className='text-center'>
                            <span
                              className={
                                e.status == 1
                                  ? 'badge badge-light-success fs-7 fw-bold'
                                  : e.status == 0
                                  ? 'badge badge-light-warning fs-7 fw-bold'
                                  : 'badge badge-light-danger fs-7 fw-bold'
                              }
                            >
                              {(e.status == 0 && 'Pending') ||
                                (e.status == 1 && 'Accepted') ||
                                (e.status == 2 && 'Rejected')}
                            </span>
                          </td>
                          <td className='text-end'>
                            <a
                              href='#'
                              className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_10'
                              onClick={() => {
                                setId(e.id)
                              }}
                            >
                              <KTIcon iconName='arrow-right' className='fs-2' />
                            </a>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div>
              <button
                className='btn btn-sm btn-primary'
                onClick={() => {
                  navigate('/bookings')
                }}
              >
                View More
              </button>
            </div>
          </div>
        </div>
      </div>
      <DashBookingModal id={id} getBookings={getAllBookings} />
    </>
  )
}

export {DashBoardTableOne}
