import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import axios from '../../Helpers/Api_instance'
import {Formik, Form, Field} from 'formik'
import {KTSVG} from '../../Ihouse/helpers'
import {toast} from 'react-toastify'

export default function App({id, getOrders}) {
  const [cats, setCats] = useState([])
  const [initialValues, setInitialValues] = useState({
    status: 0,
  })
  const onSubmit = async (values: any, {resetForm}) => {
    try {
      const res = await axios.put(`/orders/${id}`, {status: values.status})
      if (res && res.data) {
        getOrders()
        resetForm()
        toast.success('Order Updated Successfully')
      }
    } catch (error: any) {
      toast.success('Unable Update Order')
      console.log(error.message)
    }
  }

  const getOrderById = async () => {
    try {
      const res = await axios.get(`/orders/${id}`)
      if (res && res.data) {
        setInitialValues({status: res.data.order.status})
      }
    } catch (error: any) {
      console.log(error.message)
    }
  }

  useEffect(() => {
    if (id !== '') {
      getOrderById()
    }
  }, [id])

  return (
    <div>
      <div className='modal fade' tabIndex={-1} id='dashOrder'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Update Order</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG path='media/icons/duotune/arrows/arr061.svg' />
              </div>
            </div>
            <div className='modal-body'>
              <Formik initialValues={initialValues} enableReinitialize={true} onSubmit={onSubmit}>
                {({setFieldValue}) => (
                  <Form>
                    <div className='fv-row mb-7'>
                      <label className='required fs-6 fw-semibold mb-2'>Status</label>
                      <Field as='select' name='status' className='form-control'>
                        <option value='0'>Pending</option>
                        <option value='1'>Preparing</option>
                        <option value='2'>Out For Delivery</option>
                        <option value='3'>Completed</option>
                        <option value='4'>Ready To Pick</option>
                      </Field>
                    </div>
                    <div className='modal-footer'>
                      <a
                        type='button'
                        className='btn btn-light-danger me-3'
                        data-bs-dismiss='modal'
                      >
                        Close
                      </a>
                      <button
                        type='submit'
                        className='btn btn-light-primary'
                        data-bs-dismiss='modal'
                      >
                        Submit
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
