import axios from 'axios'
import { AuthModel, UserModel } from './_models'
import api_instance from '../../../../Helpers/Api_instance'
const API_URL = 'https://sale-api.indianhouse.co.uk/v1/admin'

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/protected`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {
    email,
    password,
  },
    {
      withCredentials: true
    })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return api_instance.post(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken() {
  return api_instance.get(GET_USER_BY_ACCESSTOKEN_URL, {
    withCredentials: true
  })
}