import {createRoot} from 'react-dom/client'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'
// Apps
import './Ihouse/assets/fonticon/fonticon.css'
import './Ihouse/assets/keenicons/duotone/style.css'
import './Ihouse/assets/keenicons/outline/style.css'
import './Ihouse/assets/keenicons/solid/style.css'

import './Ihouse/assets/sass/style.scss'
import './Ihouse/assets/sass/plugins.scss'
import './Ihouse/assets/sass/style.react.scss'
import {AppRoutes} from './app/routing/AppRoutes'
import {AuthProvider, setupAxios} from './app/modules/auth'

setupAxios(axios)
Chart.register(...registerables)

const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <AuthProvider>
      <AppRoutes />
    </AuthProvider>
  )
}
