/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../Ihouse/helpers'
import DashOrderModal from '../Modals/DashOrderModal'
import axios from '../../Helpers/Api_instance'
import {useNavigate} from 'react-router-dom'
import {LazyLoadImage} from 'react-lazy-load-image-component'
type Props = {
  className: string
}

const DashBoardTableTwo: React.FC<Props> = ({className}) => {
  const [delivery, setDelivery] = useState([])
  const [pickup, setPickup] = useState([])
  const [id, setId] = useState('')
  const navigate = useNavigate()

  const images = [
    toAbsoluteUrl('/media/svg/avatars/018-girl-9.svg'),
    toAbsoluteUrl('/media/svg/avatars/014-girl-7.svg'),
    toAbsoluteUrl('/media/svg/avatars/043-boy-18.svg'),
    toAbsoluteUrl('/media/svg/avatars/018-girl-9.svg'),
  ]
  const getOrders = async () => {
    try {
      const res = await axios.get('/orders')
      if (res && res.data) {
        const deliv = res.data.orders
          .filter((e: any) => e.status == 0 && e.type == 0)
          .sort((a: any, b: any) => b.id - a.id)
        const pick = res.data.orders
          .filter((e: any) => e.status == 0 && e.type == 1)
          .sort((a: any, b: any) => b.id - a.id)
        setDelivery(deliv)
        setPickup(pick)
      }
    } catch (error: any) {
      console.log(error.message)
    }
  }

  useEffect(() => {
    getOrders()
  }, [])
  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Latest Orders</span>
        </h3>
        <div className='card-toolbar'>
          <ul className='nav'>
            <li className='nav-item'>
              <a
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bold px-4 me-1'
                data-bs-toggle='tab'
                href='#kt_table_widget_7_tab_1'
              >
                Door Delivery
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-danger fw-bold px-4 me-1'
                data-bs-toggle='tab'
                href='#kt_table_widget_7_tab_2'
              >
                Store PickUp
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className='card-body py-3'>
        <div className='tab-content'>
          <div className='tab-pane fade show active' id='kt_table_widget_7_tab_1'>
            <div className='table-responsive'>
              <table className='table align-middle gs-0 gy-3'>
                <thead>
                  <tr>
                    <th className='p-0 w-50px'></th>
                    <th className='p-0 min-w-150px'></th>
                    <th className='p-0 min-w-140px'></th>
                    <th className='p-0 min-w-120px'></th>
                  </tr>
                </thead>
                <tbody>
                  {delivery.slice(0, 10).map((ele: any, ind: any) => {
                    return (
                      <tr key={ind}>
                        <td>
                          <div className='symbol symbol-50px me-2'>
                            <span className='symbol-label'>
                              <LazyLoadImage
                                effect='blur'
                                style={{
                                  width: '50px',
                                  height: '50px',
                                  objectFit: 'cover',
                                  borderRadius: '5px',
                                }}
                                src={images[Math.floor(Math.random() * 3)]}
                                className='h-75 align-self-end'
                                alt=''
                              />
                            </span>
                          </div>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                            onClick={() => {
                              navigate(`/order/${ele.reference_number}`)
                            }}
                          >
                            {ele.first_name}
                          </a>
                          <span className='text-muted fw-semibold d-block'>{ele.mobile}</span>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                            onClick={() => {
                              navigate(`/order/${ele.reference_number}`)
                            }}
                          >
                            {ele.reference_number}
                          </a>
                        </td>
                        <td className='text-center'>
                          <span className={'badge badge-light-success fs-7 fw-bold'}>
                            £{ele.Transaction?.amount}
                          </span>
                        </td>
                        <td className='text-end'>
                          <a
                            href='#'
                            className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                            data-bs-toggle='modal'
                            data-bs-target='#dashOrder'
                            onClick={() => {
                              setId(ele.id)
                            }}
                          >
                            <KTIcon iconName='arrow-right' className='fs-2' />
                          </a>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className='tab-pane fade' id='kt_table_widget_7_tab_2'>
            <div className='table-responsive'>
              <table className='table align-middle gs-0 gy-3'>
                <thead>
                  <tr>
                    <th className='p-0 w-50px'></th>
                    <th className='p-0 min-w-150px'></th>
                    <th className='p-0 min-w-140px'></th>
                    <th className='p-0 min-w-120px'></th>
                  </tr>
                </thead>
                <tbody>
                  {pickup.slice(0, 10).map((ele: any, ind: any) => {
                    return (
                      <tr key={ind}>
                        <td>
                          <div className='symbol symbol-50px me-2'>
                            <span className='symbol-label'>
                              <LazyLoadImage
                                effect='blur'
                                style={{
                                  width: '50px',
                                  height: '50px',
                                  objectFit: 'cover',
                                  borderRadius: '5px',
                                }}
                                src={images[Math.floor(Math.random() * 3)]}
                                className='h-75 align-self-end'
                                alt=''
                              />
                            </span>
                          </div>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                            onClick={() => {
                              navigate(`/order/${ele.reference_number}`)
                            }}
                          >
                            {ele.first_name}
                          </a>
                          <span className='text-muted fw-semibold d-block'>{ele.mobile}</span>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                            onClick={() => {
                              navigate(`/order/${ele.reference_number}`)
                            }}
                          >
                            {ele.reference_number}
                          </a>
                        </td>
                        <td className='text-center'>
                          <span className={'badge badge-light-success fs-7 fw-bold'}>
                            £{ele.Transaction?.amount}
                          </span>
                        </td>
                        <td className='text-end'>
                          <a
                            href='#'
                            className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                            data-bs-toggle='modal'
                            data-bs-target='#dashOrder'
                            onClick={() => {
                              setId(ele.id)
                            }}
                          >
                            <KTIcon iconName='arrow-right' className='fs-2' />
                          </a>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div>
          <button
            className='btn btn-sm btn-primary'
            onClick={() => {
              navigate('/Orders')
            }}
          >
            View More
          </button>
        </div>
        <DashOrderModal id={id} getOrders={getOrders} />
      </div>
    </div>
  )
}

export {DashBoardTableTwo}
