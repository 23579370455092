/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../Ihouse/layout/core'
import {DashCardOne} from '../../../Components/Cards/DashCardOne'
import DashBoardMain from './DashBoardMain'
import axios from '../../../Helpers/Api_instance'
import {useAuth} from '../../modules/auth'

const DashboardWrapper = () => {
  const intl = useIntl()
  const [bookings, setBookinngs] = useState(0)
  const [completed, setCompleted] = useState(0)
  const [revenue, setRevenue] = useState(0)
  const [orders, setOrders] = useState(0)
  const {setData} = useAuth()
  const getBookings = async () => {
    try {
      const res = await axios.get('/bookings')
      if (res && res.data) {
        const comple = res.data.data.filter((e: any) => {
          if (e.attended === true) {
            return e
          }
        })
        setCompleted(comple.length)
        setBookinngs(res.data.data.length)
      }
    } catch (error: any) {
      console.log(error.message)
    }
  }
  const getTransactions = async () => {
    try {
      const res = await axios.get('/transactions/all')
      if (res && res.data) {
        const transactionsWithIdOne = res.data.transactions.filter((e: any) => e.status == 1)
        if (transactionsWithIdOne.length > 0) {
          const rev = transactionsWithIdOne.reduce(
            (a, b) => a + parseFloat(b.amount ? b.amount : 0),
            0
          )
          setRevenue(rev)
        }
      }
    } catch (error: any) {
      console.log(error.message)
    }
  }
  const getOrders = async () => {
    try {
      const res = await axios.get('/orders')
      if (res && res.data) {
        setOrders(res.data.orders.length)
      }
    } catch (error: any) {
      console.log(error.message)
    }
  }

  useEffect(() => {
    getBookings()
    getTransactions()
    getOrders()
  }, [])

  useEffect(() => {
    setData({bookings, orders, revenue})
  }, [bookings, orders, revenue])
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-3'>
          <DashCardOne
            className='card-xl-stretch mb-xl-8'
            svgIcon='calendar'
            color='primary'
            iconColor='white'
            title={bookings + '+'}
            description='Total Bookings'
            titleColor='white'
            descriptionColor='white'
          />
        </div>
        <div className='col-xl-3'>
          <DashCardOne
            className='card-xl-stretch mb-xl-8'
            svgIcon='check'
            color='warning'
            iconColor='white'
            title={completed}
            description='Completed Bookings'
            titleColor='white'
            descriptionColor='white'
          />
        </div>
        <div className='col-xl-3'>
          <DashCardOne
            className='card-xl-stretch mb-xl-8'
            svgIcon='handcart'
            color='danger'
            iconColor='white'
            title={orders}
            description='Total Orders'
            titleColor='white'
            descriptionColor='white'
          />
        </div>
        <div className='col-xl-3'>
          <DashCardOne
            className='card-xl-stretch mb-xl-8'
            svgIcon='dollar'
            color='success'
            iconColor='white'
            title={revenue + '£'}
            description='Total Revenue'
            titleColor='white'
            descriptionColor='white'
          />
        </div>
      </div>
      <DashBoardMain />
    </>
  )
}

export {DashboardWrapper}
