import * as React from 'react'
import {DashBoardTableOne} from '../../../Components/Tables/DashBoardTableOne'
import {DashBoardTableTwo} from '../../../Components/Tables/DashBoardTableTwo'

export interface IAppProps {}

export default function App(props: IAppProps) {
  return (
    <div className='row g-5 gx-xxl-8'>
      <div className='col-xxl-6'>
        <DashBoardTableOne className='card-xxl-stretch mb-5 mb-xxl-8' />
      </div>
      <div className='col-xxl-6'>
        <DashBoardTableTwo className='card-xxl-stretch mb-5 mb-xxl-8' />
      </div>
    </div>
  )
}
