import * as Yup from 'yup'

export const AddFoodMenuValidation = Yup.object({
  title: Yup.string().required('Enter Your Title'),
  original_price: Yup.string().required('Enter Your Original Price'),
  subCategoryId: Yup.string().required('Please Select SubCategory'),
  categoryId: Yup.string().required('Please Select Category '),
  status: Yup.string().required('Please Select Status '),
  type: Yup.string().required('Please Select Type '),
  file: Yup.string().required('Please Upload Image '),
})

const updateFoodmenuValidations = Yup.object({
  title: Yup.string().required('Enter Your Title'),
  original_price: Yup.string().required('Enter Your Original Price'),
  subCategoryId: Yup.string().required('Please Select SubCategory'),
  categoryId: Yup.string().required('Please Select Category '),
  status: Yup.string().required('Please Select Status '),
  type: Yup.string().required('Please Select Type '),
})

export {updateFoodmenuValidations}
