import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import {updateFoodmenuValidations} from '../Validations/AddFoodMenuValidation'
import {KTIcon} from '../../Ihouse/helpers'
import axios from '../../Helpers/Api_instance'
import Icon from '../../app/Food-Item.png'
import {useNavigate, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {TimePicker} from 'antd'

const EditFoodForm = () => {
  const [initialValues, setinitialValues] = useState<any>({
    title: '',
    description: '',
    original_price: '',
    offer_price: '',
    categoryId: '',
    tag: '',
    subCategoryId: '',
    status: '',
    type: '',
    file: null,
    foodmenuImages: null,
    special: false,
    openTime: '',
    closeTime: '',
  })

  const [categories, setCategories] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [categoryId, setCategoryId] = useState('')
  const navigate = useNavigate()
  const {id} = useParams()
  const onSubmit = async (values, {setSubmitting}) => {
    console.log(values)
    try {
      const formData = new FormData()
      formData.append('title', values.title)
      formData.append('description', values.description)
      formData.append('original_price', values.original_price)
      formData.append('subCategoryId', values.subCategoryId)
      formData.append('selectedTag', values.tag)
      formData.append('categoryId', values.categoryId)
      formData.append('type', values.type)
      formData.append('status', values.status)
      formData.append('image', values.file)
      formData.append('special', values.special)
      if (values.offer_price !== '' && values.offer_price !== null) {
        formData.append('offer_price', values.offer_price)
      }
      if (values.tag !== '' && values.tag !== null) {
        formData.append('tag', values.tag)
      }
      if (values.openTime !== '') {
        formData.append('openTime', values.openTime)
      }

      if (values.closeTime !== '') {
        formData.append('closeTime', values.closeTime)
      }
      const res = await axios.put('/foodmenu/' + id, formData)
      if (res && res.data) {
        toast.success('Food Item updated successfully')
        navigate('/foodmenu')
      }
    } catch (error: any) {
      console.error('Error submitting form:', error.message)
      toast.error('Unable update food item')
    } finally {
      setSubmitting(false)
    }
  }

  const getFoodItemById = async () => {
    try {
      const res = await axios.get(`/foodmenu/${id}`)
      if (res && res.data) {
        setinitialValues(res.data.data)
        setCategoryId(res.data.data.categoryId)
      }
    } catch (error: any) {
      console.log(error.message)
    }
  }

  const getCategories = async () => {
    try {
      const res = await axios.get('/categories/all')
      if (res && res.data) {
        setCategories(res.data.data)
      }
    } catch (error: any) {
      console.log(error.message)
    }
  }

  const getSubCategories = async () => {
    try {
      const res = await axios.get('/subcategories/category/' + categoryId)
      if (res && res.data) {
        setSubCategories(res.data.subcategoies)
      }
    } catch (error: any) {
      console.log(error.message)
    }
  }

  useEffect(() => {
    getCategories()
  }, [])

  useEffect(() => {
    if (categoryId !== '') {
      getSubCategories()
    }
  }, [categoryId])
  useEffect(() => {
    getFoodItemById()
  }, [id])
  return (
    <div>
      <div className='col-sm-12 col-xl-12 col-md-12'>
        <div className='card card-custom'>
          <div className='card-header'>
            <div className='card-title m-0'>
              <div className='symbol symbol-45px w-45px bg-light me-5'>
                <img src={Icon} alt='Metronic' className='p-2' />
              </div>
              <h2>Edit Food Item</h2>
            </div>
            <div className='card-toolbar'>
              <Link
                to={'/foodmenu'}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3'
              >
                <KTIcon iconName='exit-left' className='fs-1' />
              </Link>
            </div>
          </div>
          <div className='card-body'>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              validationSchema={updateFoodmenuValidations}
              onSubmit={onSubmit}
            >
              {({setFieldValue, values}) => (
                <Form>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='mb-10'>
                        <Field
                          type='text'
                          id='title'
                          name='title'
                          className='form-control form-control-white'
                          placeholder='Enter Your Title'
                        />
                        <ErrorMessage name='title' component='div' className='text-danger mt-2' />
                      </div>
                    </div>
                    <div className='col-md-3'>
                      <div className='mb-10'>
                        <Field
                          type='text'
                          id='originalPrice'
                          name='original_price'
                          className='form-control form-control-white'
                          placeholder='Enter Your Original Price'
                        />
                        <ErrorMessage
                          name='original_price'
                          component='div'
                          className='text-danger mt-2'
                        />
                      </div>
                    </div>
                    <div className='col-md-3'>
                      <div className='mb-10'>
                        <Field
                          type='text'
                          id='offeredPrice'
                          name='offer_price'
                          className='form-control form-control-white'
                          placeholder='Enter Your Offered Price'
                        />
                      </div>
                    </div>
                    <div className='col-md-3'>
                      <div className='mb-10'>
                        <Field
                          as='select'
                          className='form-select'
                          aria-label='Select example'
                          name='categoryId'
                          onChange={(e) => {
                            setFieldValue('categoryId', e.target.value)
                            setCategoryId(e.target.value)
                          }}
                        >
                          <option>Select Category</option>
                          {categories.map((e: any, i) => {
                            return (
                              <option value={e.id} key={e.id}>
                                {e.name}
                              </option>
                            )
                          })}
                        </Field>
                        <ErrorMessage
                          name='categoryId'
                          component='div'
                          className='text-danger mt-2'
                        />
                      </div>
                    </div>

                    <div className='col-md-3'>
                      <div className='mb-10'>
                        <Field
                          as='select'
                          className='form-select'
                          aria-label='Select example'
                          name='subCategoryId'
                        >
                          <option>Select SubCategory</option>
                          {subCategories.map((e: any, i) => {
                            return (
                              <option value={e.id} key={e.id}>
                                {e.name}
                              </option>
                            )
                          })}
                        </Field>
                        <ErrorMessage
                          name='subCategoryId'
                          component='div'
                          className='text-danger mt-2'
                        />
                      </div>
                    </div>

                    <div className='col-md-3'>
                      <div className='mb-10'>
                        <Field
                          as='select'
                          className='form-select'
                          aria-label='Select example'
                          name='tag'
                        >
                          <option value=''>Select Tag</option>
                          <option value='Best Seller'>Best Seller</option>
                          <option value='Seasonal'>Seasonal</option>
                          <option value='New'>New</option>
                        </Field>
                        {/* <ErrorMessage name='tag' component='div' className='text-danger mt-2' /> */}
                      </div>
                    </div>
                    <div className='col-md-3'>
                      <div className='mb-10'>
                        <Field
                          as='select'
                          className='form-select'
                          aria-label='Select example'
                          name='status'
                        >
                          <option>Status</option>
                          <option value='1'>Active</option>
                          <option value='2'>InActive</option>
                        </Field>
                        <ErrorMessage name='status' component='div' className='text-danger mt-2' />
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='mb-10'>
                        <div className='row'>
                          <div className='col-md-2 col-sm-2'>
                            <h3>&nbsp;Type :</h3>
                          </div>
                          <div className='col-md-2 col-sm-2'>
                            <label>
                              <Field
                                type='radio'
                                value='Veg'
                                name='type'
                                className='form-check-input h-20px w-20px'
                              />
                              &nbsp; &nbsp; Veg
                            </label>
                          </div>
                          <div className='col-md-3 col-sm-3'>
                            <label>
                              <Field
                                type='radio'
                                value='Non-Veg'
                                name='type'
                                className='form-check-input h-20px w-20px'
                              />
                              &nbsp; &nbsp; Non-Veg
                            </label>
                          </div>
                        </div>
                        <ErrorMessage name='type' component='div' className='text-danger mt-2' />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='mb-10'>
                        <div className='row'>
                          <div className='col-md-2 col-sm-2'>
                            <h3>&nbsp;Special:</h3>
                          </div>
                          <div className='col-md-3 col-sm-3'>
                            <label>
                              <div className='form-check form-switch'>
                                <Field
                                  className='form-check-input'
                                  type='checkbox'
                                  name='special'
                                  id='flexSwitchCheckDefault'
                                />
                                <label
                                  className='form-check-label'
                                  htmlFor='flexSwitchCheckDefault'
                                ></label>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='mb-10'>
                        <div className='row'>
                          <div className='col-md-4 col-sm-2 d-flex align-items-center'>
                            <h3>&nbsp;Available Time:</h3>
                          </div>
                          <div className='col-md-6 col-sm-6'>
                            <label>
                              <TimePicker.RangePicker
                                style={{width: '100%', height: '45px'}}
                                // value={[]}
                                format={'HH:mm'}
                                onChange={(time, timeString) => {
                                  setFieldValue('openTime', timeString[0])
                                  setFieldValue('closeTime', timeString[1])
                                }}
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='mb-10'>
                        <Field
                          as='textarea'
                          id='description'
                          name='description'
                          className='form-control form-control-white'
                          placeholder='Enter Your Description'
                        />
                        <ErrorMessage
                          name='description'
                          component='div'
                          className='text-danger mt-2'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='mb-10'>
                        <label htmlFor='file' className='required fs-6 fw-semibold mb-2'>
                          Image
                        </label>
                        <input
                          type='file'
                          className='form-control'
                          onChange={(e: any) => {
                            setFieldValue('file', e.target.files[0])
                          }}
                        />
                        <ErrorMessage name='file' component='div' className='text-danger mt-2' />
                      </div>
                    </div>
                  </div>

                  <div className='text-end'>
                    <Link to={'/foodmenu'} className='btn btn-light-danger me-3'>
                      Cancel
                    </Link>
                    <button className='btn btn-light-primary' type='submit'>
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditFoodForm
