import {useEffect} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {HeaderWrapper} from './components/header'
import {RightToolbar} from '../partials/layout/RightToolbar'
import {ScrollTop} from './components/scroll-top'
import {Content} from './components/content'
import {Sidebar} from './components/sidebar'
// import {ActivityDrawer, DrawerMessenger, InviteUsers, UpgradePlan} from '../partials'
import {PageDataProvider} from './core'
import {reInitMenu} from '../helpers'
import io from 'socket.io-client'
import {toAbsoluteUrl} from '../helpers'
import swal from 'sweetalert'
import 'react-lazy-load-image-component/src/effects/blur.css'
const socket = io('https://sale-api.indianhouse.co.uk')
const MasterLayout = () => {
  const location = useLocation()

  useEffect(() => {
    socket.on('connection', () => {})
    socket.on('disconnect', () => {})
    socket.on('booking', (booking) => {
      swal(`New booking Arrived ${booking.reference_number}`, '', 'success')
      const audio = new Audio(toAbsoluteUrl('/media/notification.mp3'))
      audio.play()
    })

    socket.on('order', (order) => {
      const audio2 = new Audio(toAbsoluteUrl('/media/OrderSiren.wav'))
      swal({
        title: `New Order Arrived ${order.reference_number}`,
        icon: 'success',
        buttons: {
          confirm: {
            text: 'OK',
            closeModal: true,
            visible: true,
          },
        },
      }).then(() => {
        audio2.pause()
        audio2.currentTime = 0
      })
      audio2.loop = true
      audio2.play()
    })

    return () => {
      socket.disconnect()
    }
  }, [])

  useEffect(() => {
    reInitMenu()
  }, [location.key])

  return (
    <PageDataProvider>
      <div className='d-flex flex-column flex-root app-root p-6' id='kt_app_root'>
        <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
          <HeaderWrapper />
          <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
            <Sidebar />
            <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
              <div className='d-flex flex-column flex-column-fluid'>
                <Content>
                  <Outlet />
                </Content>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollTop />
    </PageDataProvider>
  )
}

export {MasterLayout}
